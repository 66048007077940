<template>
    <div class="login-section" :class="$i18n.locale == 'en' ? 'en-style' : ''">
        <div class="uk-container">
            <div
                    class="uk-child-width-1-1 uk-child-width-1-2@m uk-flex-middle"
                    uk-grid
            >
                <div class="uk-form">
                    <div class="form-border">
                        <v-form lazy-validation ref="form" class="uk-form-stacked">
                            <h4>{{$t('form.enterNewPassword')}}</h4>
                            <!-- inpt 1 -->
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label>{{$t('form.password')}}</label>
                                    <v-text-field
                                            v-model="password"
                                            :rules="passwordRules"
                                            :placeholder="$t('form.password')"
                                            autocomplete="off"
                                            autocorrect="off"
                                            type="password"
                                    ></v-text-field>
                                </div>
                            </div>
                            <!-- end input 1 -->
                            <!-- inpt 1 -->
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label>{{$t('form.confirmPassword')}}</label>
                                    <v-text-field
                                            v-model="passwordConfirm"
                                            :rules="[v => v === password || $t('required.notIdentecalPassword')]"
                                            :placeholder="$t('form.confirmPassword')"
                                            autocomplete="off"
                                            autocorrect="off"
                                            type="password"
                                    ></v-text-field>
                                </div>
                            </div>
                            <!-- end input 1 -->
                            <div class="uk-margin">
                                <base-button @click="send()"
                                             :options="{block :true , isLoading : loading , color:this.primaryColor}">
                                    {{$t('form.submit')}}
                                </base-button>
                            </div>
                        </v-form>
                    </div>
                </div>
                <div class="uk-img">
                    <img :src="loginImg" alt=""/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions} from "vuex";
    import BaseButton from "../../../core/Base/Buttons/BaseButton";

    export default {
        name: "ResetPassword",
        components: {BaseButton},
        data() {
            return {
                /**
                 * @author karam mustafa
                 * @task #BACT-101
                 * */
                loading: false,
                /**
                 * @author karam mustafa
                 * @task #BACT-101
                 * */
                loginImg: require('../../../assets/img/login.png'),
                /**
                 * @author karam mustafa
                 * @task #BACT-101
                 * */
                password: '',
                /**
                 * @author karam mustafa
                 * @task #BACT-101
                 * */
                passwordConfirm: '',
                /**
                 * @desc check if password is correct
                 * @author karam mustafa
                 * @task #BACT-101
                 * */
                passwordRules: [
      (v) => !!v || this.$t("required.password"),
      (v) => v.length >= 10 || this.$t("required.validPassword")
    ],
            }
        },
        methods: {
            ...mapActions('Auth', ['resetPassword']),
            /**
             * @author karam mustafa
             * @task #BACT-101
             * */
            send() {
                if (!this.$refs.form.validate()) return;
                this.loading = true;
                this.resetPassword({
                    password: this.password,
                    hash: this.$route.params.hash
                }).then(() => {
                    this.loading = false;
                    setTimeout(() => {
                        this.$router.push({name: 'login'})
                    }, 2000)
                }).catch(() => {
                    this.loading = false;
                })
            }
        },
    }
</script>

<style scoped>
.en-style {
  direction: ltr;
}
</style>
